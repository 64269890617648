.image-list{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(250px,1fr));
    grid-gap: 0 10px;
    grid-auto-rows: 10px;
}

.image-list img{
    width: 250px;
    grid-row-end: span 2;
}